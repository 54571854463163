
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReceiveContent",

  props: {
    showScan: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
  },

  emits: ["update:modelValue", "update:showScan"],

  data: () => ({
    code: "",
    loading: false,
  }),

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
    scan: {
      get() {
        return this.showScan;
      },
      set(value: boolean) {
        this.$emit("update:showScan", value);
      },
    },

    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },

    canContinue() {
      return this.code.length == 8;
    },
  },

  watch: {
    show(val) {
      if (val) return;
      this.code = "";
      this.loading = false;
    },
  },

  methods: {
    send() {
      this.loading = true;
      this.$router.push("/b/" + this.code);
    },
  },
});
