import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
    title: "Отправить",
    permanent: "",
    description: "Не загружайте никакой конфиденциальный информации.",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.show) = $event))
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Content, {
        ref: "content",
        text: _ctx.text,
        "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
        files: _ctx.files,
        "onUpdate:files": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.files) = $event))
      }, null, 8, ["text", "files"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_Btn, {
        label: "Добавить файлы",
        style: {"margin-right":"auto"},
        onClick: _ctx.addFile
      }, null, 8, ["onClick"]),
      (_ctx.$device.isDesktop)
        ? (_openBlock(), _createBlock(_component_Btn, {
            key: 0,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false)),
            label: "Отмена"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Btn, {
        label: "Продолжить",
        onClick: _ctx.send,
        filled: "",
        loading: _ctx.loading,
        disabled: !_ctx.canContinue
      }, null, 8, ["onClick", "loading", "disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}