import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_ScanContent = _resolveComponent("ScanContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Content, {
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
      showScan: _ctx.showScanner,
      "onUpdate:showScan": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showScanner) = $event))
    }, null, 8, ["modelValue", "showScan"]),
    _createVNode(_component_ScanContent, {
      modelValue: _ctx.showScanner,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showScanner) = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}