
import { defineComponent } from "vue";
import { QrcodeStream } from "vue3-qrcode-reader";

export default defineComponent({
  name: "ScannerContent",

  components: { QrcodeStream },

  props: {
    modelValue: { type: Boolean, default: false },
  },

  emits: ["update:modelValue"],

  data: () => ({
    error: "",
    loading: true,
    camPermission: true,
  }),

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },

    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },

    showCam() {
      return !this.loading && this.camPermission && !this.error;
    },
  },

  watch: {
    show() {
      this.error = "";
      this.loading = true;
      this.camPermission = true;
    },
  },

  methods: {
    onDecode(result: string) {
      if (!result.startsWith("http") || location.host != new URL(result).host)
        return;

      const { pathname } = new URL(result);
      this.$router.push(pathname);
    },

    async onInit(promise: any) {
      try {
        await promise;
        this.loading = false;
      } catch (error: any) {
        if (error.name === "NotAllowedError") this.camPermission = false;
        else this.error = "Ошибка камеры: " + error.name;
      }
    },

    openManual() {
      window.open(
        "https://lumpics.ru/how-to-enable-access-permission-for-webcam-in-browser/",
        "_blank"
      );
    },
  },
});
