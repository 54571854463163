
import { defineComponent } from "vue";
import Send from "@/components/send";
import Receive from "@/components/receive";

export default defineComponent({
  name: "Main View",

  components: { Send, Receive },

  computed: {
    send: {
      get() {
        return "send" in this.$route.query;
      },

      set(v: boolean) {
        if (v) this.$router.push({ query: { send: null } });
        else if (window?.history.state?.back == "/") this.$router.back();
        else this.$router.replace("/");
      },
    },
    receive: {
      get() {
        return "receive" in this.$route.query;
      },

      set(v: boolean) {
        if (v) this.$router.push({ query: { receive: null } });
        else if (window?.history.state?.back == "/") this.$router.back();
        else this.$router.replace("/");
      },
    },
  },

  methods: {
    supportBtn() {
      window.open("https://pay.cloudtips.ru/p/55f81819", "_blank");
    },
  },
});
