import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Btn = _resolveComponent("Btn")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
    title: "Получить",
    permanent: "",
    description: "Для получения доступа введите восьми значный код.",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.show) = $event))
  }, {
    body: _withCtx(() => [
      _withDirectives(_createVNode(_component_Field, {
        label: "Код файла",
        modelValue: _ctx.code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
        autocomplete: "off"
      }, null, 8, ["modelValue"]), [
        [_directive_focus]
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_Btn, {
        label: "Сканировать",
        style: {"margin-right":"auto"},
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scan = true))
      }),
      (_ctx.$device.isDesktop)
        ? (_openBlock(), _createBlock(_component_Btn, {
            key: 0,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false)),
            label: "Отмена"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Btn, {
        label: "Продолжить",
        filled: "",
        onClick: _ctx.send,
        loading: _ctx.loading,
        disabled: !_ctx.canContinue
      }, null, 8, ["onClick", "loading", "disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}